
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddBalanceModal from "@/components/modals/forms/AddBalanceModal.vue"
import AddPointModal from "@/components/modals/forms/AddPointModal.vue"

export default defineComponent({
  components: {
    AddBalanceModal,
    AddPointModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("流水排名列表", ["流水排名管理;water_items"])
    })

    const store = useStore()
    const router = useRouter()
    const tableData = ref([])
    const currentMemberId = ref(0)

    const setCurrentMemberId = (id) => {
      currentMemberId.value = id
    }

    const formData = ref({
      nickname: "",
      mobile: "",
      type: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const water_duration = ref([
      from,
      to,
    ])

    const query = () => {
      let params = {
        ...formData.value,
        from: `${water_duration.value[0].toLocaleDateString()} ${water_duration.value[0].getHours()}:${water_duration.value[0].getMinutes()}:${water_duration.value[0].getSeconds()}`,
        to: `${water_duration.value[1].toLocaleDateString()} ${water_duration.value[1].getHours()}:${water_duration.value[1].getMinutes()}:${water_duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_WATER_ITEMS, { ...params, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentWaterItems
          page.value.totalResult = store.getters.currentWaterItemCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        nickname: "",
        mobile: "",
        type: ''
      };
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      water_duration
    }
  },
})
